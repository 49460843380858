<template>
    <div class="p-0">
        <v-layer ref="konvaLayer" :listening="layerVisible" :opacity="layerVisible ? 1 : 0.4">
            <faceOne :config="config_face_one" ></faceOne>
            <faceTwo :config="config_face_two" :teeth="teeth" @selected="onSelectedFace" @deselected="onDeselectedFace" :apliedFill="faceFill.faceTwo"></faceTwo>
            <faceThree :config="config_face_three" :teeth="teeth" @selected="onSelectedFace" @deselected="onDeselectedFace" :apliedFill="faceFill.faceThree"></faceThree>
            <faceFour :config="config_face_four" :teeth="teeth" @selected="onSelectedFace" @deselected="onDeselectedFace" :apliedFill="faceFill.faceFour"></faceFour>
            <faceFive :config="config_face_five" :teeth="teeth" @selected="onSelectedFace" @deselected="onDeselectedFace" :apliedFill="faceFill.faceFive" ></faceFive>
            <v-text :config="config_text" />
        </v-layer>
    </div>
</template>
<script>
import faceTwo from './faces/faceTwo';
import faceThree from './faces/faceThree';
import faceFive from './faces/faceFive';
import faceFour from './faces/faceFour';
import faceOne from './faces/faceOne';

export default {
    name: "pieza",
    components: { faceOne ,faceTwo, faceThree,faceFour, faceFive },
    props: ['teeth',"index",'apliedFill','layerVisible'],
    data() {
        return {
            config_text: {
                x: (19 + (this.index * 50)),
                y: 58,
                text: this.teeth.number,
                fontSize: 12
            },
            config_face_one: {
                x:(18 + (this.index * 50)),
                y: 22,
                fill: "transparent",
                width: 13,
                height: 13,
                strokeWidth: 0.5,
                stroke: 'black',
            },
            config_face_two: {
                x: (18 + (this.index * 50)),
                y: 7,
                fill: "transparent",
                width: 13,
                height: 13,
                strokeWidth: 0.5,
                stroke: 'black',
            },
            config_face_three: {
                x: (33 + (this.index * 50)),
                y: 22,
                fill: "transparent",
                width: 13,
                height: 13,
                strokeWidth: 0.5,
                stroke: 'black',
            },
            config_face_four: {
                x: (18 + (this.index * 50)),
                y: 37,
                fill: "transparent",
                width: 13,
                height: 13,
                strokeWidth: 0.5,
                stroke: 'black',
            },
            config_face_five: {
                x: (3 + (this.index * 50)),
                y: 22,
                fill: "transparent",
                width: 13,
                height: 13,
                strokeWidth: 0.5,
                stroke: 'black',
            },
        };
    },
    methods : {
        onSelectedFace($eventInfo){
           this.$emit('selectedFace', $eventInfo);
        },
        onDeselectedFace($eventInfo){
           this.$emit('deselectedFace', $eventInfo);
        },
    },
    computed : {
        faceFill(){
            return {
                "faceTwo" : this.apliedFill.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F2' ),
                "faceThree" : this.apliedFill.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F3'),
                "faceFour" : this.apliedFill.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F4'),
                "faceFive" : this.apliedFill.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F5' ),
            }
        }
    }
};
</script>
