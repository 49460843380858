<template>
<main>
    <button class="btn btn-outline-secondary btn-sm " data-toggle="modal" data-target="#modalIncapacidades" >
        <i class="fas fa-share fa-fw"></i>
        Incapacidades
    </button>
    <div class="modal fade" id="modalIncapacidades" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Incapacidad</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="n-tab" data-toggle="tab" href="#nuevo-i" role="tab" aria-controls="new-incapacidad" aria-selected="true">Nueva Incapacidad</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="li-tab" data-toggle="tab" href="#listado-i" role="tab" aria-controls="list-incapacidad" aria-selected="false">Listado Incapacidades</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="nuevo-i" role="tabpanel" aria-labelledby="nuevo-i-tab">
                        <create v-on:cargar-incapacidades="cargar" :idHistoria="idHistoria" :usuario="usuario"></create>
                    </div>
                    <div class="tab-pane fade" id="listado-i" role="tabpanel" aria-labelledby="listado-i-tab">
                            <list ref="listIncapacidades"  :idHistoria="idHistoria"></list>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</main>
   
</template>
<script>
import create from './create';
import list from './list';
export default {
    components:{create,list},
    props: ['idHistoria','usuario'],
    methods: {
        async cargar(){
            this.$refs.listIncapacidades.cargarIncapacidades(this.idHistoria);
        },
    }
}
</script>

