<template>
    <main>
        <div class="card card-header-actions shadow-none border-0">
            <div class="card-body ">
                <div class="row" v-if="!Object.keys(tratamiento).length || tratamiento.id_historia==idHistoria">
                    <div class="form-group col-md-2">
                        <label class="form-label">No. Sesiones <small class="text-danger">*</small></label>
                        <input type="number" class="form-control" v-model="sesiones" >
                    </div>
                    <div class="form-group col-md-8">
                        <label class="form-label">Observaciones</label>
                        <textarea class="form-control form-control-sm" v-model="observaciones"
                            ></textarea>
                    </div>
                    <button class="btn btn-success btn-sm" @click="guardarSesiones()"><i class="fa fa-save fa-fw"></i> Guardar Sesiones</button>
                </div>

                <div class="table-responsive" v-else>
                    <span  class="badge badge-success-soft badge-marketing mb-2 mx-2"><h3>Sesiones Programadas: {{this.tratamiento.sesiones}}</h3></span>
                   
                    <div class="row mb-3">
                        <div class="form-group col-md-8 col-lg-10">
                            <label class="font-weight-bolder">Observaciones</label>
                            <textarea class="form-control form-control-sm" v-model="detalle.observaciones"
                                ></textarea>
                        </div>
                        <div class="form-group col-md-4 col-lg-2">
                        <button class="btn btn-success btn-sm mt-4" @click="guardarDetalle()">Guardar</button>
                        </div>
                    </div>
                  
                    <span>Historico de sesiones</span>
                    <table class="table table-borderless mb-0 table-sm mt-2">
                        <thead class="border-bottom bg-light">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">No. Sesion</th>
                                <th>No. Historia</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Observaciones</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" v-for="(item) in detalles_tratamiento" :key="item.id">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.no_sesion }}
                                    </div>
                                </td>
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.id_historia }}
                                    </div>
                                </td>
                                <td class="small text-muted">
                                    {{ item.fecha }}
                                </td>
                                <td class="small text-muted">
                                    {{ item.observaciones }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>  
</template>
<script>
import { isEmpty } from 'lodash';
import Toast from '../../../../components/common/utilities/toast';
import sesionesTratamientoService from '../../../../services/sesionesTratamientoService';

export default {
    props: ['idHistoria', 'usuario', 'idTipoHistoria', 'finalizada'],
    data() {
        return {
            id_historia: '',
            sesiones: '',
            observaciones: '',
            items: [],
            tratamiento:{},
            detalle:{
                observaciones: '',
            },
            detalles_tratamiento:[]
        }
    },
  
    methods: {
        async guardarSesiones() {
            try {
                if (this.sesiones==='') {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar un numero de sesiones'
                    });
                    return;
                }
                const tratamiento = {
                    id_historia: this.idHistoria,
                    id_usuario:this.usuario.id,
                    sesiones: this.sesiones,
                    observaciones: this.observaciones
                };
                this.LoaderSpinnerShow();

                await sesionesTratamientoService.store(tratamiento);
                this.cargarTratamiento();
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ourrido un error al procesar la solicitud'
                });
            }
        },
        async guardarDetalle() {
            try {
                
                if (this.detalle.observaciones==='') {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar una observacion'
                    });
                    return;
                }

                this.LoaderSpinnerShow();

                let sesion={
                    id_historia: this.idHistoria,
                    id_tratamiento:this.tratamiento.id,
                    observaciones:this.detalle.observaciones,
                    no_sesion:this.tratamiento.sesion_actual+1
                }
                await sesionesTratamientoService.storeDetalleSesion(sesion);
                this.cargarDetalleTratamiento();
            
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ourrido un error al procesar la solicitud'
                });
            }
        },
        async cargarTratamiento(){
            const response = await sesionesTratamientoService.showByIdUser(this.usuario.id);
            this.tratamiento=response.data;
            this.sesiones=response.data.sesiones;
            this.observaciones=response.data.observaciones;
            if(!isEmpty(response.data)){
                this.cargarDetalleTratamiento();
            }else{
                const response = await sesionesTratamientoService.showDetalleByIdHistoria(this.idHistoria);
                this.detalles_tratamiento = response.data.tratamiento.detalles;
                this.tratamiento=response.data.tratamiento;
            }
        },
        async cargarDetalleTratamiento(){
            const response = await sesionesTratamientoService.showDetalleByIdTratamiento(this.tratamiento.id);
            this.detalles_tratamiento = response.data;
            //this.detalle.observaciones=response.data.observaciones
        }
    },
    async created(){
        this.cargarTratamiento();
    }
}
</script>
