<template>
  <div class="col-12">
    <div class="row">
      <div class="col-lg-3" :title="conventions.name" v-for="convention in conventions" :key="convention.id">
        <div class="">
          <button class="btn btn-light border-blue-soft btn-xs mt-2 justify-content-between"
            @click="onSelectConvention(convention)">
            <svg height="20" width="20">
              <path :d="convention.svgPath" transform="scale(0.1)" :stroke="convention.fill" :fill="convention.fill" />
            </svg>
            <span style="font-size: 10px;" class="text-black mt-0 p-0">({{ convention.servicio.codigo }}) {{
            convention.servicio.descripcion }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="card shadow-none mt-2">
      <div class="card-header text-center bg-light">Dientes Permanentes</div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6" v-for="(item, index) in permanentTeethByQuadrant" :key="index">
              <div class="row p-0">
                <v-stage :config="configKonva" :ref="'stagePermanent' + index">
                  <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                    <div class="justify-content-between p-0 ">
                      <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions"
                        @selectedFace="onSelectedFace">
                      </Pieza>
                    </div>
                  </div>
                </v-stage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow-none mt-2">
        <div class="card-header text-center bg-light">Dientes Temporales</div>
          <div class="card-body ">
            <div class="row">
              <div class="col-lg-6" v-for="(item, index) in temporaryTeethByQuadrant" :key="index">
                <div class="row">
                  <v-stage :config="configKonva" :ref="'stageTemporary' + index">
                    <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                      <div class="justify-content-between">
                        <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions"
                          @selectedFace="onSelectedFace">
                        </Pieza>
                      </div>
                    </div>
                  </v-stage>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="table-responsive mt-2">
        <table class="table table-bordered mb-0 table-sm">
          <thead class="border-bottom">
            <tr class="small text-uppercase text-muted table-active">
              <th scope="col">Procedimientos</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(item, index) in groupTheet" :key="index">
              <div>
                <th rowspan="2" class="font-bold number-column align-middle text-center table-active">{{ item.number }}
                </th>
                <td class="face-column text-center table-active" scope="col" v-for="(face, index) in item.faces"
                  :key="`fac_${index}`">
                  {{ face.name }}
                </td>
              <tr>
                <td class="face-column text-center" scope="col" v-for="(face, faceIndex) in item.faces" :key="`proc_${faceIndex}`">
                  <span class="badge badge-info-soft badge-marketing" v-if="buscarConvencion(item.number, face.faceCode)">
                    {{ buscarConvencion(item.number, face.faceCode) }} <i
                      @click="deleteConvention(item.number, face.faceCode)"
                      class="fas fa-times-circle text-danger fa-fw pointer"></i>
                  </span>
                </td>
              </tr>
            </div>
          </tr>
        </tbody>
      </table>
  </div>
  <serviciosAdicionales ref="procedimientosRealizados"></serviciosAdicionales>
  </div>

</template>

<script>
import Pieza from "./pieza/pieza.vue"
import odontogramaAdulto from "@/assets/odontograma/odontogramaAdulto.json";
import Toast from '../../../../components/common/utilities/toast';
import convencionesProcedimientoOdontogramaService from '@/services/convencionesProcedimientoOdontogramaService';
import historiaOdontogramaProcedimientoService from '@/services/historiaOdontogramaProcedimientoService';
import { isEmpty } from 'lodash';
import serviciosAdicionales from "./serviciosAdicionales";
export default {
  components: {
    Pieza,serviciosAdicionales
  },
  props: ['idHistoria', 'usuario', 'finalizada'],
  data() {
    return {
      configKonva: {
        width: 400,
        height: 80,
      },
      permanentTeeth: odontogramaAdulto.permanent,
      temporaryTeeth: odontogramaAdulto.temp,
      conventions: [],
      apliedConventions: [],
      selectedConvention: null,
      permanentTeethByQuadrant: [],
      temporaryTeethByQuadrant: [],
      imgQuadrants: [],
    };
  },
  methods: {
    onSelectedFace($eventInfo) {
      if (this.selectedConvention) {
        const faceCode = this.selectedConvention.type === 'all' ? 'F1' : $eventInfo.faceCode;
        this.apliedConventions.push({
          faceInfo: {
            dentNumber: $eventInfo.dentNumber,
            faceCode: faceCode,
          },
          conventionInfo: this.selectedConvention,
          teethInfo: $eventInfo.teethInfo
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Debe seleccionar una convención'
        });
      }
    },
    onSelectConvention(conventionInfo) {
      this.selectedConvention = conventionInfo
    },
    groupTeethsByQuadrant() {

      const groupPermanentTeeth = {};

      this.permanentTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupPermanentTeeth[quadrantKey]) {
          groupPermanentTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupPermanentTeeth[quadrantKey].teeths.push(teeth);
      });

      this.permanentTeethByQuadrant = { ...groupPermanentTeeth };

      const groupTemporaryTeeth = {};

      this.temporaryTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupTemporaryTeeth[quadrantKey]) {
          groupTemporaryTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupTemporaryTeeth[quadrantKey].teeths.push(teeth);
      });

      this.temporaryTeethByQuadrant = { ...groupTemporaryTeeth };

    },
    downloadSVG() {
      const stages = [];
      // Iterar sobre las referencias y filtrar las que comienzan con "stagePermanent"
      for (const key in this.$refs) {
        if (key.startsWith('stagePermanent')) {
          stages.push({
            quadrant: key.replace('stagePermanent', 'perm_'),
            typeDent: 'permanent',
            stage: this.$refs[key]
          });
        }
        if (key.startsWith('stageTemporary')) {
          stages.push({
            quadrant: key.replace('stageTemporary', 'temp_'),
            typeDent: 'temporary',
            stage: this.$refs[key]
          });
        }
      }
      //console.log(stages);
      let stagesToSave = [];

      stagesToSave = stages.map(x => {
        const base64 = x.stage[0].getStage().toDataURL();
        return {
          quadrant: x.quadrant,
          typeDent: x.typeDent,
          binaryImg: base64,
          base64: base64,
        }
      })

      this.imgQuadrants = stagesToSave;
    },
    buscarConvencion(dentNumber, faceCode) {
      const convencion = this.apliedConventions.find(item => {
        return item.faceInfo.dentNumber === dentNumber && item.faceInfo.faceCode === faceCode;
      });

      return convencion ? ('(' + convencion.conventionInfo.servicio.codigo + ')' + ' ' + convencion.conventionInfo.servicio.descripcion) : '';
    },
    deleteConvention(dentNumber, faceCode) {
      const indexToRemove = this.apliedConventions.findIndex(item => item.faceInfo.dentNumber === dentNumber && item.faceInfo.faceCode === faceCode);
      if (indexToRemove !== -1) {
        this.apliedConventions.splice(indexToRemove, 1);
      }
    },
    async getConventions() {
      const response = await convencionesProcedimientoOdontogramaService.index();
      this.conventions = response.data;
    },
    async save() {
      try {
        this.LoaderSpinnerShow();

        this.downloadSVG();

        let dataOdontograma = {
          idHistoria: this.idHistoria,
          apliedConventions: this.apliedConventions.map(x => {
            return {
              conventionId: x.conventionInfo.id,
              faceCode: x.faceInfo.faceCode,
              dentNumber: x.faceInfo.dentNumber
            }
          }),
        }

        const orden = this.$refs.procedimientosRealizados.retornar();
       
        if (!isEmpty(orden)) {
          dataOdontograma['procedimientos'] = orden;
        }

        const response = await historiaOdontogramaProcedimientoService.store(dataOdontograma);

        for (let index = 0; index < this.imgQuadrants.length; index++) {
          await historiaOdontogramaProcedimientoService.storeGraphics({
            idHistoria: this.idHistoria,
            idOdontograma: response.data.id,
            imgQuadrants: this.imgQuadrants[index],
          });
        }

        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con éxito'
        });
      } catch (error) {
        console.log(error);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al procesar la solicitud'
        });
      }
    }
  },
  async mounted() {
    this.groupTeethsByQuadrant();
    this.getConventions();
    if (this.finalizada === 'N') {
      const response = await historiaOdontogramaProcedimientoService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.apliedConventions = response.data.detalles.map(x => {
          return {
            conventionInfo: x.procedimiento,
            faceInfo: {
              dentNumber: x.dentNumber,
              faceCode: x.faceCode
            }
          }
        })
      }
      this.$refs.procedimientosRealizados.getProcedimientos(this.idHistoria);
    }
  },
  computed: {
    groupTheet() {

      const numerosDientesSet = new Set();

      this.apliedConventions.forEach(item => {
        numerosDientesSet.add(item.faceInfo.dentNumber);
      });
      // Convertir el Set de nuevo a un array para mantener la estructura de datos consistente
      const numerosDientes = Array.from(numerosDientesSet);

      const teeths = [];

      this.permanentTeeth.forEach(item => {
        if (numerosDientes.includes(item.number)) {
          teeths.push(item);
        }
      });

      this.temporaryTeeth.forEach(temporay => {
        if (numerosDientes.includes(temporay.number)) {
          teeths.push(temporay);
        }
      });

      return teeths;
    }
  },
  watch: {
    finalizada: async function (value) {
      if (value === 'N') {
        const response = await historiaOdontogramaProcedimientoService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.apliedConventions = response.data.detalles.map(x => {
            return {
              conventionInfo: x.procedimiento,
              faceInfo: {
                dentNumber: x.dentNumber,
                faceCode: x.faceCode
              }
            }
          })
        }
      }
    }
  }
};
</script>

<style scoped>
.number-column {
  width: 100px;
}

.face-column {
  width: 150px;
}

.convention-column {
  width: 150px;
}

.pointer {
  cursor: pointer;
}
</style>