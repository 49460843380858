<template>
    <main>
        <div class="card shadow-none  mt-3">
            <div class="card-header">
                <h4 class="card-title mb-0">Servicios Adicionales</h4>
            </div>

            <div class="card-body ">
                <div class="form-group mt-2">
                    <label class="form-label">Procedimiento / Examen <small class="text-danger">*</small></label>
                    <v-select-servicio :groups="[2, 3, 4, 5]" v-model="item.servicio" mode="object"
                        :class="{ 'is-error': $v.item.servicio.$error }">
                    </v-select-servicio>
                    <span v-if="!$v.item.servicio.required && $v.item.servicio.$dirty" class="text-danger">
                        <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                    </span>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                        <label class="form-label">Cantidad <small class="text-danger">*</small></label>
                        <input type="number" v-model="item.cantidad" class="form-control form-control-sm"
                            :class="{ 'is-invalid': $v.item.cantidad.$error && $v.item.cantidad.$dirty }">
                    </div>
                    <div class=" mt-4 py-2 col-md-2  text-center">
                        <button class="btn btn-primary btn-sm" @click="agregarServicio()">
                            <i class="fas fa-plus fa-fw"></i>Agregar
                        </button>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-borderless mb-0 table-sm">
                        <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Examen/Procedimiento</th>
                                <th scope="col">Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" v-for="(item, index) in items" :key="item.id">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.servicio.codigo }}
                                        {{ item.servicio.descripcion }}
                                    </div>
                                </td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <button class="btn btn-transparent rounded-circle" @click="removerServicio(index)">
                                        <i class="fas fa-trash-alt text-danger"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import { required } from "vuelidate/lib/validators";
import historiaOdontogramaProcedimientoService from "../../../../services/historiaOdontogramaProcedimientoService";
import {isEmpty} from "lodash";
export default {
    components: { vSelectServicio },
    data() {
        return {

            item: {
                cantidad: '1',
                observacion: 'NINGUNA',
                servicio: {},
            },
            items: [],
            ordenes: []
        }
    },
    validations() {
        return {
            item: {
                cantidad: { required },
                servicio: { required },
            }
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({ ...this.item });
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        retornar() {
            return this.items;
        },

        limpiarCampos() {
            this.item = {};
            this.items = [];
        },
        async getProcedimientos(id){
            const response=await historiaOdontogramaProcedimientoService.showProcedimientosRealizadosByHc(id);
                if(!isEmpty(response.data)){
                    this.items = response.data.items.map(x=>{
                        return {
                            servicio:x.servicio,
                            cantidad:x.cantidad
                        }
                    });
                
                }
        }
    },
}
</script>
