<template>
    <div> 
        <div class="row mt-2">
            <div class="col-lg-4 form-group">
                <label class="small mb-1 font-weight-bolder">Desde <small class="text-danger">*</small></label>
                <input  v-model="incapacidad.fecha_inicial" class="form-control form-control-sm" type="date"
                :class="{
                    'is-invalid' : $v.incapacidad.fecha_inicial.$error && $v.incapacidad.fecha_inicial.$dirty
                }"/>
                <span class="invalid-feedback" v-if="!$v.incapacidad.fecha_inicial.required">
                    Este campo es requerido
                </span>
            </div> 

            <div class="col-lg-4">
                <label class="small mb-1 font-weight-bolder">Hasta <small class="text-danger">*</small></label>
                <input  v-model="incapacidad.fecha_final" class="form-control form-control-sm" type="date"
                :class="{
                    'is-invalid' : $v.incapacidad.fecha_final.$error && $v.incapacidad.fecha_final.$dirty
                }"/>
                <span class="invalid-feedback" v-if="!$v.incapacidad.fecha_final.required">
                    Este campo es requerido
                </span>
            </div>

            <div class="col-lg-4">
                <label class="small mb-1 font-weight-bolder">Total Días <small class="text-danger">*</small></label>
                <input  v-model="totalDias" class="form-control form-control-sm" type="number"/>
            </div>
    
            <div class="col-lg-12">
                <label  class="form-label font-weight-bolder"><i class="far fa-comment-alt fa-fw"></i> Descripción <small class="text-danger">*</small></label>
                <div class="">
                    <textarea  v-model="incapacidad.descripcion" class="form-control" :class="{'is-invalid':$v.incapacidad.descripcion.$error && $v.incapacidad.descripcion.$dirty}"></textarea>
                    <span class="text-danger text-sm" v-if="!$v.incapacidad.descripcion.minLengthValue">Debe ingresar un minimo de 20 caracteres</span>
                </div>
            </div>   
        </div>

        <button class="btn btn-success btn-sm mt-2" @click="guardar()">
            <i class="fas fa-save"></i>&nbsp;Guardar
        </button>
    </div>
</template>
<script>
import incapacidadService from '../../../../services/incapacidadService';
import {minLength, required} from "vuelidate/lib/validators";
import Toast from '../../../../components/common/utilities/toast';
import dayjs from "dayjs";
export default {
    props:['idHistoria','usuario'],
    data() {
        return{
            incapacidad:{
                id_historia:this.idHistoria,
                fecha_inicial:'',
                fecha_final:'',
                total_dias:'',
                descripcion:''
            }
        }
    },
    validations (){
        return {
            incapacidad:{ 
                fecha_inicial:{required},
                fecha_final:{required},
                descripcion:{
                    required,
                    minLengthValue:minLength(20),
                },
            }
        }
    },
    methods: {
        async guardar() {
            try {
                this.$v.$touch();
                if(this.$v.$invalid){
                    return
                }
               
                this.LoaderSpinnerShow();
                this.incapacidad.total_dias = this.totalDias;
                await incapacidadService.store(this.incapacidad);
                this.limpiarCampos();
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-incapacidades');
            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos(){
            this.incapacidad={};
        },
    },
    computed:{
        totalDias() {
            if (!this.incapacidad.fecha_inicial || !this.incapacidad.fecha_final) {
                return '';
            }
            const startDate = dayjs(this.incapacidad.fecha_inicial);
            const endDate = dayjs(this.incapacidad.fecha_final);
            return endDate.diff(startDate, 'day')+1;
        }
    }
}
</script>
