<template>
    <main>
        <textarea v-model="val"  class="form-control" :classObject="classObject" rows="1"></textarea>
        <button @click="selectOption(i)" 
            v-for="(opcion,i) in antecedente.opciones" 
            :data-tipo="`tipo_${opcion.id}`" 
            :key="`${opcion.id}_tipo`"
            class="btn btn-xs btn-secondary mr-1 mt-2 shadow-sm text-secondary-soft"
            >
            <i class="fa fa-circle fa-fw fa-1x fa-fw"></i>{{ opcion.valor}}
        </button>
    </main>
</template>
<script>
export default {
    props: ['antecedente','value','classObject'],
    data () {
        return{
            val:this.value,
        }
    },
    methods:{
        selectOption(i) {
            const tipo = this.antecedente.opciones[i];
            this.val = tipo.valor;
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        },
        value: function (newValue) {
            if (newValue !== this.val) {
                this.val = newValue;
            }
        },
    }
}
</script>


