import Service from "./Service";
const baseurl = '/api/historia-incapacidad';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showIncapacidadByHc(id_historia) {
        return Service.get(`${baseurl}/show-incapacidad-hc/${id_historia}`);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`;
    },
}